body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  max-width: 100vw;
  background-color: rgb(25, 33, 52) !important;
  background: rgb(25, 33, 52);
  background: linear-gradient(180deg,
      rgba(25, 33, 52, 1) 28%,
      rgba(7, 8, 21, 1) 75%);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-input {
  background-color: #1f2639 !important;
  color: white !important;
  border-width: 0px !important;
  height: 96px !important;
  margin-bottom: 5px;
  font-size: 35px;
  border-radius: 12px !important;
}

.ant-input::placeholder {
  color: #5f6783 !important;
}

.ant-popover-arrow {
  visibility: hidden;
}

.ant-popover-title {
  color: white !important;
}

.ant-popover-inner-content {
  color: white !important;
}

.ant-popover-inner {
  min-width: 260px !important;
  min-height: 140px !important;
  border: 1px solid #21273a;
  background-color: #0e111b !important;
}

.ant-radio-group {
  margin-top: 10px;
}

.ant-radio-button-wrapper {
  background-color: #1f2639 !important;
  color: white !important;
  font-weight: 500;
  border-color: white !important;
}

.ant-radio-button-wrapper-checked {
  border-color: white !important;
  background-color: #363e54 !important;
}

.ant-radio-button-wrapper-checked::before {
  background-color: white !important;
}

.ant-modal-content {
  background-color: #0e111b !important;
  color: white !important;
  padding: 0px !important;
}

.ant-modal {
  border: 1px solid #363e54;
  width: 400px !important;
  border-radius: 10px;
}

.ant-modal-header {
  background-color: #0e111b !important;
}

.ant-modal-title {
  color: white !important;
  padding-top: 17px !important;
  margin-left: 20px !important;
}

.ant-modal-close-icon {
  color: #363e54 !important;
  transition: 0.3s;
}

.ant-modal-close-icon:hover {
  color: white !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgb(14, 14, 51);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(126, 109, 109, 0.3);
}